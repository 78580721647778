<template>
  <div>
    <Loader :loading="loading" />
    <div v-if="pageReady">
      <TenderCard :tender="tender" />
      <v-divider class="my-2"></v-divider>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Tender attribute</th>
              <th class="text-left">Tender Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Tender Title</td>
              <td>{{ tender.title }}</td>
            </tr>
            <tr></tr>
            <tr>
              <td>Tender Number</td>
              <td>{{ tender.tender_no }}</td>
            </tr>
            <tr v-if="tender.status">
              <td>Status</td>
              <td v-if="tender.status.slug == 'open'" class="success--text">
                {{ tender.status.name }}
              </td>
              <td v-if="tender.status.slug == 'closed'" class="error--text">
                {{ tender.status.name }}
              </td>
              <td v-if="tender.status.slug == 'evaluated'" class="primary--text">
                {{ tender.status.name }}
              </td>
              <td v-if="tender.status.slug == 'draft'" class="grey--text">
                {{ tender.status.name }}
              </td>
            </tr>
            <tr>
              <td>Application Fee</td>
              <td>{{ tender.application_fee ? toMoneyFormat(tender.application_fee) : 0 }}</td>
            </tr>
            <tr>
              <td>Closing Date</td>
              <td>{{ tender.closing_date }}</td>
            </tr>
            <tr>
              <td>Clarification End Date</td>
              <td>{{ tender.clarification_end_date }}</td>
            </tr>
            <tr>
              <td>Documents</td>
              <td>
                <div
                  class="py-1 my-3 justify-center"
                  v-for="(item, i) in tender.documents"
                  :key="i"
                >
                  <v-btn
                    depressed
                    color="error"
                    x-small
                    dark
                    text
                    @click="deleteItem(item)"
                    v-if="user.is_admin"
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                  <a
                    link
                    v-bind:href="item.url"
                    class="text-decoration-underline py-5"
                    >({{ i + 1 }}) {{ item.name }}
                  </a>
                </div>
                <v-col cols="12" sm="6" md="6">
                  <v-file-input
                    color="grey"
                    prepend-icon=""
                    outlined
                    dense
                    v-model="fileToBeUploaded"
                    @change="handleFileUpload"
                    v-if="user.is_admin"
                  >
                    <template v-slot:label>
                      <span class="input__label">
                        <v-icon small color="success"
                          >mdi-plus-thick</v-icon
                        ></span
                      >Click to upload new
                    </template>
                  </v-file-input>
                </v-col>
              </td>
            </tr>
            <tr>
              <td>Clarifications</td>
              <td>
                <v-btn
                  class="mx-auto"
                  :elevation="0"
                  dark
                  small
                  rounded
                  color="warning lighten-1"
                  @click="clarificationPreview(tender)"
                >
                  <span class="font-weight-bold button">
                    {{
                      tender.clarifications_answered +
                      "/" +
                      tender.clarifications_count
                    }}</span
                  >
                </v-btn>
              </td>
            </tr>
            <tr v-if="user.is_admin">
              <td>Evaluators</td>
              <td>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="tender_evaluators"
                    :disabled="isUpdating"
                    :items="evaluators"
                    filled
                    chips
                    color="primary"
                    label="Select Tender evaluators"
                    item-text="name"
                    item-value="id"
                    multiple
                    outlined
                    @change="handleEvaluators"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeEvaluators(data.item)"
                        color="primary"
                        dark
                      >
                        <v-avatar left>
                          <v-img :src="data.item.image_url"></v-img>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="data.item.image_url"
                            max-height="150"
                            max-width="250"
                            :src="data.item.image_url"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.role.title"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </td>
            </tr>
            <tr v-if="tender.status != 2">
              <td>Actions</td>
              <td>
                <v-btn
                  v-if="!user.is_admin"
                  color="primary"
                  class="text-capitalize"
                  dark
                  @click="goToTender()"
                  >Create application</v-btn
                >

                <v-dialog
                  v-model="dialog"
                  scrollable
                  max-width="800px"
                  v-if="!user.is_admin"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="text-capitalize mx-3"
                      dark
                      v-bind="attrs"
                      v-if="checkInterest(tender)"
                      v-on="on"
                    >
                      Express Interest?
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>Interest for Participation</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: 400px">
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Message"
                          placeholder="Your message here"
                          class="mt-5"
                          color="grey"
                          :value="interest.content"
                          v-model="interest.content"
                          height="350"
                          :rules="rules.required"
                        ></v-textarea>
                      </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        dark
                        @click="dialog = false"
                      >
                        Close <v-icon small>mdi-cancel</v-icon>
                      </v-btn>
                      <v-btn
                        color="primary darken-1"
                        dark
                        @click="showInterest"
                        :loading="loading"
                      >
                        Send <v-icon small>mdi-send</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-btn
                  v-if="user.is_admin"
                  color="primary"
                  class="text-capitalize"
                  dark
                  @click="editItem(tender)"
                  >Edit Tender</v-btn
                >

                <v-btn
                  v-if="user.is_admin"
                  color="error"
                  class="text-capitalize mx-3"
                  dark
                  @click="deleteTenderItem(tender)"
                  >Delete Tender</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-col cols="12" sm="1" align="right">
        <v-row justify="center">
          <v-dialog v-model="dialogEdit" scrollable max-width="950" persistent>
            <v-card>
              <v-card-title class="error--text text--darken-1">Edit</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 500px">
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="4" md="4">
                        <v-text-field
                          v-model="editedItem.tender_no"
                          label="Tender Number"
                          :rules="rules.required"
                          color="grey"
                          dense
                          outlined
                          class="mb-n3"
                        >
                          <template v-slot:label>
                            <span class="input__label"
                              >Tender Number
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="8" md="8" dense>
                        <v-text-field
                          v-model="editedItem.title"
                          label="Tender Title"
                          :rules="rules.required"
                          color="grey"
                          dense
                          outlined
                          class="mb-n3"
                        >
                          <template v-slot:label>
                            <span class="input__label"
                              >Tender Title
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12" class="my-n5">
                        <v-textarea
                          v-model="editedItem.description"
                          outlined
                          name="input-7-4"
                          label="Tender Details"
                          color="grey"
                          dense
                          :rules="rules.required"
                          class="mb-n3"
                        >
                          <template v-slot:label>
                            <span class="input__label"
                              >Tender Details
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template>
                        </v-textarea>
                      </v-col>

                      <v-col cols="12" sm="4" md="4">
                        <v-select
                          v-model="editedItem.category"
                          :items="tenderCategories"
                          label="Select Category"
                          item-text="title"
                          item-value="id"
                          color="grey"
                          dense
                          outlined
                          :rules="rules.select"
                        >
                          <template v-slot:label>
                            <span class="input__label"
                              >Tender Category
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-select
                          v-model="editedItem.application_steps"
                          :items="tender_steps"
                          label="Application Steps"
                          color="grey"
                          item-value="id"
                          item-text="name"
                          dense
                          outlined
                          :rules="rules.select"
                        >
                          <template v-slot:label>
                            <span class="input__label"
                              >Application Steps
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template>
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="4" md="4">
                        <v-text-field
                          v-model="editedItem.budget"
                          label="Budget"
                          outlined
                          dense
                          color="grey"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4" md="4">
                        <v-text-field
                          v-model="editedItem.application_fee"
                          label="Application Fee"
                          outlined
                          dense
                          color="grey"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4" md="4">
                        <v-select
                          v-model="editedItem.status"
                          :items="tender_status"
                          label="Status"
                          color="grey"
                          dense
                          outlined
                          :rules="rules.select"
                          item-value="id"
                          item-text="name"
                        >
                          <template v-slot:label>
                            <span class="input__label"
                              >Tender Status
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template></v-select
                        >
                      </v-col>
                      <!-- Closing date  -->
                      <v-col cols="12" sm="4" md="4">
                        <v-menu
                          ref="menu_closing_date"
                          v-model="menu_closing_date"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.closing_date"
                              :value="computedClosingDate"
                              label="Closing Date"
                              persistent-hint
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                              color="grey"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="closingDateWatcher"
                            no-title
                            @change="menu_closing_date = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Clarification end date  -->
                      <v-col cols="12" sm="4" md="4">
                        <v-menu
                          ref="menu_clarification_date"
                          v-model="menu_clarification_date"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.clarification_end_date"
                              :value="computedClarificationDate"
                              label="Clarification End Date"
                              persistent-hint
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                              color="grey"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="clarificationDateWatcher"
                            no-title
                            @change="menu_clarification_date = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Evaluation end date  -->
                      <v-col cols="12" sm="4" md="4">
                        <v-menu
                          ref="menu_clarification_date"
                          v-model="menu_evaluation_date"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.evaluation_end_date"
                              :value="computedEvaluationDate"
                              label="Evaluation End Date"
                              persistent-hint
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                              color="grey"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="evaluationDateWatcher"
                            no-title
                            @change="menu_evaluation_date = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- Approval end date  -->
                      <v-col cols="12" sm="4" md="4">
                        <v-menu
                          ref="menu_approval_date"
                          v-model="menu_approval_date"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.tender_approval_date"
                              :value="computedApprovalDate"
                              label="Tender Approval Date"
                              persistent-hint
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                              color="grey"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="approvalDateWatcher"
                            no-title
                            @change="menu_approval_date = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- required docs  -->
                      <v-col cols="12" sm="12" md="12" class="mt-n5">
                        <v-select
                          v-model="editedItem.required_documents"
                          :value="editedItem.required_documents"
                          :rules="rules.select2"
                          :items="required_documents"
                          label="Mandatory Documents"
                          item-text="name"
                          item-value="id"
                          multiple
                          outlined
                          chips
                          color="grey"
                          item-color="grey"
                          class="my-1 mx-1"
                        >
                          <template v-slot:label>
                            <span class="input__label"
                              >Mandatory Documents
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggleRequired">
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    editedItem.required_documents.length > 0
                                      ? 'error'
                                      : ''
                                  "
                                >
                                  {{ iconRequired }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item disabled>
                              <v-list-item-avatar color="grey lighten-3">
                                <v-icon> mdi-file-outline </v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content
                                v-if="likesAllRequiredDocument"
                              >
                                <v-list-item-title>
                                  All Documents selected for mandatory fields!
                                </v-list-item-title>
                              </v-list-item-content>

                              <v-list-item-content
                                v-else-if="likesSomerequired_documents"
                              >
                                <v-list-item-title>
                                  Document Count
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ editedItem.required_documents.length }}
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-content v-else>
                                <v-list-item-title>
                                  Which documents are mandatory for this tender?
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  Go ahead, make a selection above!
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>

                      <!-- technical docs -->
                      <v-col cols="12" sm="12" md="12" class="mt-n5">
                        <v-select
                          v-model="editedItem.technical_documents"
                          :value="editedItem.technical_documents"
                          :rules="rules.select2"
                          :items="technical_documents"
                          label="Technical Documents"
                          item-text="name"
                          item-value="id"
                          multiple
                          outlined
                          chips
                          color="grey"
                          item-color="grey"
                          class="my-1 mx-1"
                        >
                          <template v-slot:label>
                            <span class="input__label"
                              >Technical Documents
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggleTechnical">
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    editedItem.technical_documents.length > 0
                                      ? 'error'
                                      : ''
                                  "
                                >
                                  {{ iconTechnical }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item disabled>
                              <v-list-item-avatar color="grey lighten-3">
                                <v-icon> mdi-file-outline </v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content
                                v-if="likesAllTechnicalDocument"
                              >
                                <v-list-item-title>
                                  All Documents selected for technical fields!
                                </v-list-item-title>
                              </v-list-item-content>

                              <v-list-item-content
                                v-else-if="likesSometechnical_documents"
                              >
                                <v-list-item-title>
                                  Document Count
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ editedItem.technical_documents.length }}
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-content v-else>
                                <v-list-item-title>
                                  Which documents are technical for this tender?
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  Go ahead, make a selection above!
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>

                      <!-- financial docs -->
                      <v-col cols="12" sm="12" md="12" class="mt-n5">
                        <v-select
                          v-model="editedItem.financial_documents"
                          :value="editedItem.financial_documents"
                          :rules="rules.select2"
                          :items="financial_documents"
                          label="Financial Documents"
                          item-text="name"
                          item-value="id"
                          multiple
                          outlined
                          chips
                          color="grey"
                          item-color="grey"
                          class="my-1 mx-1"
                        >
                          <template v-slot:label>
                            <span class="input__label">
                              Financial Documents
                              <v-icon small color="error" class="mt-n2"
                                >mdi-star-outline</v-icon
                              ></span
                            >
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggleFinancial">
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    editedItem.financial_documents.length > 0
                                      ? 'error'
                                      : ''
                                  "
                                >
                                  {{ iconFinancial }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item disabled>
                              <v-list-item-avatar color="grey lighten-3">
                                <v-icon> mdi-file-outline </v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content
                                v-if="likesAllFinancialDocument"
                              >
                                <v-list-item-title>
                                  All Documents selected for financial fields!
                                </v-list-item-title>
                              </v-list-item-content>

                              <v-list-item-content
                                v-else-if="likesSomefinancial_documents"
                              >
                                <v-list-item-title>
                                  Document Count
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ editedItem.financial_documents.length }}
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-content v-else>
                                <v-list-item-title>
                                  Which documents are financial for this tender?
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  Go ahead, make a selection above!
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  class="text-capitalize mx-1"
                  dark
                  @click="close"
                >
                  Cancel <v-icon small>mdi-cancel</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  class="text-capitalize mx-1"
                  :loading="loading"
                  @click="save"
                >
                  Submit <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      <v-divider class="my-2"></v-divider>
    </div>
  </div>
</template>

<script>
import TenderCard from "@/components/TenderCard";
import Loader from "@/components/Loader";
import TenderService from "../../services/tender.service";
import moment from "moment";

export default {
  components: {
    TenderCard,
    Loader,
  },
  props: {
    id: {},
  },
  data: (vm) => ({
      tender_evaluators: [],
      evaluators: [],
      isUpdating: false,
      pageReady: false,
      loading: true,

      items: [],
      required_documents: [],
      technical_documents: [],
      financial_documents: [],
      editedIndex: -1,
      closingDateWatcher: new Date().toISOString().substr(0, 10),
      menu_closing_date: false,
      clarificationDateWatcher: new Date().toISOString().substr(0, 10),
      menu_clarification_date: false,
      evaluationDateWatcher: new Date().toISOString().substr(0, 10),
      menu_evaluation_date: false,
      approvalDateWatcher: new Date().toISOString().substr(0, 10),
      menu_approval_date: false,

      editedItem: {
        tender_no:
          "ABC/" +
          Math.floor(Math.random() * 9999 + 1000) +
          "/" +
          moment().format("yyyy"),
        title: "",
        description: "",
        category: "",
        budget: "",
        application_fee: "",
        application_steps: "",
        closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
        clarification_end_date: vm.formatDate(
          new Date().toISOString().substr(0, 10)
        ),
        evaluation_end_date: vm.formatDate(
          new Date().toISOString().substr(0, 10)
        ),
        tender_approval_date: vm.formatDate(
          new Date().toISOString().substr(0, 10)
        ),
        status: "",
        required_documents: [],
        technical_documents: [],
        financial_documents: [],
        required_document_ids: [],
        technical_document_ids: [],
        financial_document_ids: [],
      },
      defaultItem: {
        tender_no:
          "ABC/" +
          Math.floor(Math.random() * 9999 + 1000) +
          "/" +
          moment().format("yyyy"),
        title: "",
        description: "",
        category: "",
        budget: "",
        application_fee: "",
        application_steps: "",
        closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
        clarification_end_date: vm.formatDate(
          new Date().toISOString().substr(0, 10)
        ),
        evaluation_end_date: vm.formatDate(
          new Date().toISOString().substr(0, 10)
        ),
        tender_approval_date: vm.formatDate(
          new Date().toISOString().substr(0, 10)
        ),
        status: "",
        required_documents: [],
        technical_documents: [],
        financial_documents: [],
        required_document_ids: [],
        technical_document_ids: [],
        financial_document_ids: [],
      },

      tender_steps: [
        {
          id: 1,
          name: "One step",
        },
        {
          id: 2,
          name: "Two step",
        },
        {
          id: 3,
          name: "Three step",
        },
      ],

      tender_status: [
        {
          id: 1,
          name: "Draft",
        },
        {
          id: 2,
          name: "Open",
        },
        {
          id: 3,
          name: "Closed",
        },
        {
          id: 4,
          name: "Evaluated",
        },
      ],
      tenderCategories: [],

      interest: {},
      valid: true,
      applyBtn: true,
      showInterestBtn: true,
      dialog: false,
      dialogEdit: false,
      remember: 0,
      timeout: 2000,
      selected: [0],
      tender_attribute: [],
      tender: {
        clarifications_answered: 0,
        clarifications_count: 0,
      },
      fileToBeUploaded: {},
      docURI:
        "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
      documentFileToBeUploaded: {},
      rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
    },
  }),
  computed: {
    companyId() {
      return this.$store.state.auth.user.company_id;
    },
    user() {
      return this.$store.state.auth.user;
    },
    computedClosingDate() {
      return this.formatDate(this.editedItem.closing_date);
    },
    computedClarificationDate() {
      return this.formatDate(this.editedItem.clarification_end_date);
    },
    computedEvaluationDate() {
      return this.formatDate(this.editedItem.evaluation_end_date);
    },
    computedApprovalDate() {
      return this.formatDate(this.editedItem.tender_approval_date);
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // technical docs
    likesAllTechnicalDocument() {
      return (
        this.editedItem.technical_documents.length ===
        this.technical_documents.length
      );
    },
    likesSometechnical_documents() {
      return (
        this.editedItem.technical_documents.length > 0 &&
        !this.likesAllTechnicalDocument
      );
    },
    iconTechnical() {
      if (this.likesAllTechnicalDocument) return "mdi-close-box";
      if (this.likesSometechnical_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // financial docs
    likesAllFinancialDocument() {
      return (
        this.editedItem.financial_documents.length ===
        this.financial_documents.length
      );
    },
    likesSomefinancial_documents() {
      return (
        this.editedItem.financial_documents.length > 0 &&
        !this.likesAllFinancialDocument
      );
    },
    iconFinancial() {
      if (this.likesAllFinancialDocument) return "mdi-close-box";
      if (this.likesSomefinancial_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    closingDateWatcher() {
      this.editedItem.closing_date = this.formatDate(this.closingDateWatcher);
    },
    clarificationDateWatcher() {
      this.editedItem.clarification_end_date = this.formatDate(
        this.clarificationDateWatcher
      );
    },
    evaluationDateWatcher() {
      this.editedItem.evaluation_end_date = this.formatDate(
        this.evaluationDateWatcher
      );
    },
    approvalDateWatcher() {
      this.editedItem.tender_approval_date = this.formatDate(
        this.approvalDateWatcher
      );
    },
  },

  created() {
    this.getTender();
    this.getAllTenders();
    this.setDetails(Object.entries(this.tender));
    this.getAllEvaluators();
    this.getTenderEvaluators();
    this.getCategories();
    this.getAllRequiredDocuments();
    this.getAllTechnicalDocuments();
    this.getAllFinancialDocuments();
  },

  isUpdating(val) {
    if (val) {
      setTimeout(() => (this.isUpdating = false), 3000);
    }
  },

  methods: {
    getInitials: function (str) {
      return str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    },
    handleEvaluators() {
      let data = {};
      data.evaluators = this.tender_evaluators;
      data.tender_id = this.id;
      TenderService.addEvaluators(data).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Tender Evaluators set!");
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
      console.log(this.tender_evaluators);
    },
    removeEvaluators(item) {
      const index = this.tender_evaluators.indexOf(item.id);
      if (index >= 0) this.tender_evaluators.splice(index, 1);
      this.handleEvaluators();
    },
    getAllEvaluators() {
      return TenderService.getAllEvaluators().then(
        (response) => {
          if (response.status == 200) {
            this.evaluators = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.evaluators = [];
          console.log(error);
        }
      );
    },
    getTenderEvaluators() {
      return TenderService.getTenderEvaluators(this.id).then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.tender_evaluators = response.data.data.evaluator_ids;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tender_evaluators = [];
          console.log(error);
        }
      );
    },
    clarificationPreview(item) {
      this.$router.push("/clarification/" + item.id);
    },
    getTender() {
      return TenderService.showTender(this.id).then(
        (response) => {
          if (response.status == 200) {
            this.tender = response.data.data;
            this.$store.dispatch("tender/setSelected", response.data.data);
            this.loading = false;
            this.pageReady = true;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllTenders() {
      return TenderService.getAllTenders().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    setDBDateFormat() {
      // Set date formt
      this.editedItem.closing_date = this.reverseFormatDate(
        this.editedItem.closing_date
      );
      this.editedItem.clarification_end_date = this.reverseFormatDate(
        this.editedItem.clarification_end_date
      );
      this.editedItem.evaluation_end_date = this.reverseFormatDate(
        this.editedItem.evaluation_end_date
      );
      this.editedItem.tender_approval_date = this.reverseFormatDate(
        this.editedItem.tender_approval_date
      );
    },
    revertDBDateFormat() {
      // Set date formt
      this.editedItem.closing_date = this.formatDate(
        this.editedItem.closing_date
      );
      this.editedItem.clarification_end_date = this.formatDate(
        this.editedItem.clarification_end_date
      );
      this.editedItem.evaluation_end_date = this.formatDate(
        this.editedItem.evaluation_end_date
      );
      this.editedItem.tender_approval_date = this.formatDate(
        this.editedItem.tender_approval_date
      );
    },
    // required docs
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllRequiredDocument) {
          this.editedItem.required_documents = [];
        } else {
          this.editedItem.required_documents = this.required_documents.slice();
        }
      });
    },
    getAllRequiredDocuments() {
      return TenderService.getAllRequiredDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.required_documents = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    // technical docs
    toggleTechnical() {
      this.$nextTick(() => {
        if (this.likesAllTechnicalDocument) {
          this.editedItem.technical_documents = [];
        } else {
          this.editedItem.technical_documents = this.technical_documents.slice();
        }
      });
    },
    getAllTechnicalDocuments() {
      return TenderService.getAllTechnicalDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.technical_documents = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    // financial docs
    toggleFinancial() {
      this.$nextTick(() => {
        if (this.likesAllFinancialDocument) {
          this.editedItem.financial_documents = [];
        } else {
          this.editedItem.financial_documents = this.financial_documents.slice();
        }
      });
    },
    getAllFinancialDocuments() {
      return TenderService.getAllFinancialDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.financial_documents = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    reverseFormatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    getCompanyName(company) {
      return company.company_name;
    },
    editItem(item) {
      // this.editedIndex = this.items.indexOf(item); ?
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      TenderService.deleteTenderDocument(item).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Tender Document deleted");
            this.getTender();
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.message = error.response.data.message;
          } else {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
          this.loading = false;
        }
      );
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file) {
      console.log("uploading...");
      let formData = {};
      formData["tender_id"] = this.tender.id;
      formData["name"] = file.name;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      TenderService.addDocument(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Tender Document uploaded!");
            this.getTender();
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.message = error.response.data.message;
          } else {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
          this.loading = false;
        }
      );
    },
    checkInterest(item) {
      if (item.interested == 1) {
        return false;
      }

      return true;
    },
    showInterest() {
      this.$refs.form.validate();
      if (!this.valid) {
        this.loading = false;
        return false;
      }
      this.loading = true;
      let formData = {};
      formData["content"] = this.interest.content;
      formData["tender_id"] = this.tender.id;
      formData["applicant_id"] = this.user.id;
      TenderService.showInterest(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Interest sent!");
            this.getTender();
            this.loading = false;
            this.dialog = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.loading = false;
            this.message = error.response.data.message;
          } else {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
        }
      );
    },
    setDetails(tender) {
      tender.forEach((element) => {
        this.tender_attribute.push(element);
      });
    },
    
    toMoneyFormat(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    goToTender() {
      this.$router.push(
        "/tender-application-" +
          this.tender.application_steps +
          "/" +
          this.tender.id
      );
    },
    goToClarification() {
      this.$router.push("/clarification");
    },

    getCategories() {
      return TenderService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.tenderCategories = response.data.data;
          } else {
            this.tenderCategories = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenderCategories = [];
          console.log(error);
        }
      );
    },
    deleteTenderItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        TenderService.deleteTender(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },
    close() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        this.setDBDateFormat();
        // edit
        if (this.editedIndex > -1) {
          // convert back to db date
          Object.assign(this.items[this.editedIndex], this.editedItem);
          if (typeof this.editedItem.category != "number") {
            this.editedItem.category = this.editedItem.category.id;
          }
          if (typeof this.editedItem.status != "number") {
            this.editedItem.status = this.editedItem.status.id;
          }
          // check docs
          try {
            if (this.editedItem.required_documents[0].id) {
              this.editedItem.required_documents = this.editedItem.required_document_ids;
            }
            if (this.editedItem.technical_documents[0].id) {
              this.editedItem.technical_documents = this.editedItem.technical_document_ids;
            }
            if (this.editedItem.financial_documents[0].id) {
              this.editedItem.financial_documents = this.editedItem.financial_document_ids;
            }
          } catch (error) {
            console.log(error);
          }

          TenderService.updateTender(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.getAllTenders();
                this.loading = false;
                this.close();
                this.$router.push('/tenders');
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Invalid data, please check the form try again!"
                  // response.response.statusText
                );
                this.revertDBDateFormat();
                this.getAllTenders();
                this.loading = false;
              }
            },
            (error) => {
              console.log(error);
              this.revertDBDateFormat();
              this.getAllTenders();
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            }
          );
        }
      } 
    },
  },
};
</script>